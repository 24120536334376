import React, { useEffect, useState, lazy, useRef, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    Row,
    Col,
    Breadcrumb,
    Form,
    Button,
    Accordion,
    Spinner,
    FormCheck,
} from 'react-bootstrap';
import { useNavigate, useParams } from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.css';
const ObsStructure = lazy(() => import('./ObsStructure'));
import 'react-toastify/dist/ReactToastify.css';
import * as AuthSelector from '../../store/auth/selectors';
import * as AuditSelector from '../../store/audit-sheet/selectors';
import { client } from '../../services/apiService';
const ParameterList = lazy(() => import('./ParameterList'));
import Select2 from 'react-select/creatable';
import { getSingleAuditSheet } from '../../store/audit-sheet/actions';
const RendererFormControl = lazy(
    () => import('../../formRender/JsonSchemaAndRenderer')
);
import {
    ErrorNotify,
    findUniqueArrayOfOptions,
    handleKeyPress,
    imagePath,
    successNotify,
    urlPath,
    validateNameWithRegex,
} from '../../CommonLogic';
import {
    materialRenderers,
    materialCells,
} from '@jsonforms/material-renderers';
import {
    InputFormControl,
    InputFormTester,
} from '../../formRender/FormRenderComponent/InputText';
import {
    DropdownFormControl,
    DropdownFormTester,
} from '../../formRender/FormRenderComponent/Enum';
import {
    DateFormControl,
    DateFormTester,
} from '../../formRender/FormRenderComponent/Date';

export const criticalArray = ["Business", "Customer", "Compliance"];

export const responseTypeArray = [
    "Yes, No, NA, Partial",
    "Excellent, Average, Fair, Needs Improvement, Unsatisfactory, NA",
    "1, 2, 3, 4, 5, NA",
    "Compliant, Non-Compliant, NA",
    "Complete, Incomplete, NA",
    "Pass, Fail, NA",
    "Observed, Not Observed, NA",
    "Met, Not Met, Partially Met, NA",
    "Acceptable, Unacceptable, NA",
    "Fatal, Non-Fatal, NA",
    "Critical, Severe, Medium, Minor, NA",
    "Coaching, No Coaching, NA"
];

import { paraMeterOptionsDefault, subParaMeterOptionsDefault } from "./AuditsheetPredefinedMapping";

const AuditSheet = ({ mode }) => {

    document.title = `${mode} Audit sheet | Omind`;
    const orgStrId = useSelector(AuthSelector.orgStrId);
    const loggedInUser = useSelector(AuthSelector.loggedInUser);
    const sheetDataFromUrl = useSelector(AuditSelector.auditSheet);
    const [selectedDepartment, setSelectedDepartment] = useState(null);
    const [selectedDeptParent, setSelectedDeptParent] = useState(null);
    const auditSheetsHome = `/o/${orgStrId}/audit-sheet/list`;
    const [options, setOptions] = useState(paraMeterOptionsDefault);
    const [subParaMeterOptions, SetSubParaMeterOptions] = useState([]);
    const { sheetId } = useParams();
    const [sheetData, setSheetData] = useState({});
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [tat, setTat] = useState('');
    const [parameter, setParameter] = useState('');
    const [subParameter, setSubParameter] = useState('');
    const [description, setDescription] = useState('');
    const [criticalCheckbox, setCriticalCheckbox] = useState('noncritical');
    const [critical, setCritical] = useState({});
    const [subParamCritical, setSubParamCritical] = useState("");
    const [responseType, setResponseType] = useState("Yes, No, NA, Partial");
    const [fatal, setFatal] = useState(false);
    const [fatalChance, setFatalChance] = useState(false);
    const [noOfTimes, setNoOfTimes] = useState(0);
    const [durationType, setDurationType] = useState('');
    const [weightage, setWeightage] = useState('');
    const [totalWeightage, setTotalWeightage] = useState(0);
    const [isEdited, setIsEdited] = useState(false);
    const [parameterData, setParameterData] = useState({});
    const [validated, setValidated] = useState(false);
    const [obsValidation, setObsValidation] = useState(false);
    const [schema, setSchema] = useState({ type: 'object', properties: {} });
    const [uiSchema, setUischema] = useState({
        type: 'VerticalLayout',
        elements: [],
    });
    const [renderers, setRenderers] = useState([
        ...materialRenderers,
        { tester: InputFormTester, renderer: InputFormControl },
        { tester: DateFormTester, renderer: DateFormControl },
        { tester: DropdownFormTester, renderer: DropdownFormControl },
    ]);
    const [inputValues, setInputValues] = useState([]);
    const [hasRemark, setHasRemark] = useState(false);
    const newOptionInputRef = useRef(null);

    // eslint-disable-next-line react/display-name
    const CustomParameter = React.forwardRef(
        ({ id, placeholder, onChange, value, maxLength, isNotValid }, ref) => {
            return (
                <Select2
                    id={id}
                    onKeyDown={e =>
                        validateNameWithRegex(e, e => e, {
                            characterLimit: maxLength,
                        })
                    }
                    value={
                        value?.length <= 250 && {
                            label: value,
                            value: value,
                        }
                    }
                    formatCreateLabel={e => {
                        return "Add new Parameter '" + e.trim() + "'";
                    }}
                    className={isNotValid ? 'invalid' : ''}
                    options={options.filter((el) => el.value !== value)}
                    placeholder={placeholder}
                    onChange={onChange}
                    maxLength={maxLength}
                />
            );
        }
    );

    const CustomSubParameter = React.forwardRef(
        ({ id, placeholder, onChange, value, maxLength, isNotValid }, ref) => {
            return (
                <Select2
                    id={id}
                    onKeyDown={e =>
                        validateNameWithRegex(e, e => e, {
                            characterLimit: maxLength,
                        })
                    }
                    value={
                        value?.length <= 250 && {
                            label: value,
                            value: value,
                        }
                    }
                    formatCreateLabel={e => {
                        return "Add new Sub-Parameter '" + e.trim() + "'";
                    }}
                    className={isNotValid ? 'invalid' : ''}
                    options={subParaMeterOptions.filter((el) => el.value !== value)}
                    placeholder={placeholder}
                    onChange={onChange}
                    maxLength={maxLength}
                />
            );
        }
    );

    useEffect(() => {
        if (mode == 'Edit') dispatch(getSingleAuditSheet(sheetId));
    }, [sheetId]);

    useEffect(() => {
        if (sheetDataFromUrl && Object.keys(sheetDataFromUrl).length > 0) {
            setSheetData(sheetDataFromUrl);
        }
    }, [sheetDataFromUrl]);

    useEffect(() => {
        if (sheetData?.attributes?.Department) {
            setSelectedDepartment({
                label: sheetData.attributes.Department.data.attributes
                    .Department_Name,
                value: sheetData.attributes.Department.data.id,
                formName:
                    sheetData.attributes.Department.data.attributes.Display_Name?.replace(
                        / - Autogenerated/gi,
                        ''
                    ),
            });
            setTat(sheetData.attributes.Audit_Response_Time);
            const { paramData, criticalSet } = transformData(sheetData.attributes);
            setCritical(criticalSet);
            setParameterData(paramData);
            const options = [];
            for (const key in paramData) {
                options.push({
                    label: key,
                    value: key,
                });
            }
            const uniqueParameterOptions = findUniqueArrayOfOptions([...options, ...paraMeterOptionsDefault])
            setOptions(uniqueParameterOptions);
        }
        if (sheetData?.attributes?.Custom_Fields_UI && sheetData?.attributes?.Custom_Fields_Schema) {
            setSchema(sheetData?.attributes?.Custom_Fields_Schema);
            setUischema(sheetData?.attributes?.Custom_Fields_UI);
        }
    }, [sheetData]);

    // This useEffect is for focusing on new Option
    useEffect(() => {
        if (newOptionInputRef.current) {
            newOptionInputRef.current.focus();
        }
    }, [inputValues.length]);

    const transformData = apiFormat => {
        const paramData = {};
        const criticalSet = {};

        for (const param of apiFormat?.Audit_Parameters?.data) {
            if (!paramData[param.attributes.Parameter_Name]) {
                paramData[param.attributes.Parameter_Name] = [];
            }
            if (param?.attributes?.Audit_Subparameters?.data) {
                const criticalityExist = _.get(param, 'attributes.Audit_Subparameters.data[0].attributes.Critical_Type');
                criticalSet[param.attributes.Parameter_Name] = criticalArray.includes(criticalityExist) ? 'critical' : 'noncritical';
                for (let subParam of param.attributes.Audit_Subparameters.data) {
                    let subParameterData = { ...subParam.attributes };
                    if (!criticalArray.includes(subParameterData.Critical_Type)) {
                        subParameterData = { ...subParameterData, Critical_Type: '' };
                    }
                    paramData[param.attributes.Parameter_Name].push(subParameterData);
                }
            }
        }
        return { paramData, criticalSet };
    };

    const handleCheckboxChange = e => {
        const checked = e.target.checked;
        setFatalChance(checked);
        if (checked) {
            setFatal(false);
        }
        setNoOfTimes('');
        setDurationType('');
    };

    const handleDurationTypeChange = e => {
        setDurationType(e.target.value);
    };

    const handleFatal = e => {
        const checked = e.target.checked;
        setFatal(checked);
        if (checked) {
            setFatalChance(false);
        }
        setFatalChance(false);
        setNoOfTimes('');
        setDurationType('');
    };

    const hanldeAddSubParameter = event => {
        setValidated(true);
        setIsEdited(true);
        const form = event.currentTarget.form;
        if (form.checkValidity() == false || !parameter || parameter == '') {
            event.preventDefault();
            event.stopPropagation();
            return true;
        }

        const parameterName = parameter.value;
        if (!_.get(critical, parameterName, "")) {
            setCritical({ ...critical, [parameterName]: criticalCheckbox });
        }
        const subParameterName = subParameter.value;
        const updatedParameters = { ...parameterData };
        const params = Object.keys(updatedParameters);

        const subParam = {
            Subparameter_Name: subParameterName,
            Weightage: weightage === '' ? 0 : +weightage,
            Description: description,
            Is_Fatal: fatal,
            Fatal_Count: +noOfTimes,
            Fatal_Duration: durationType,
            Audit_Form: "",
            Fatal_If_Repeated: fatalChance,
            Has_Remark: hasRemark,
            Has_L2_Dropdown: inputValues.length > 0,
            Audit_L_2_Options: inputValues.map((option) => option.trim()),
            Critical_Type: subParamCritical,
            Response_Type: responseType
        };

        if (!params.includes(parameterName)) {
            updatedParameters[parameterName] = [subParam];
        } else {
            const subParameterExists = updatedParameters[parameterName].some(
                item => item.Subparameter_Name.trim() === subParameterName.trim()
            );

            if (subParameterExists) {
                ErrorNotify('Sub Parameter already exists in the parameter');
                return;
            } else {
                updatedParameters[parameterName] = [
                    ...(updatedParameters[parameterName] || []),
                    subParam,
                ];
            }
        }

        setValidated(false);
        setParameterData(updatedParameters);
        setTotalWeightage(getTotalWeight(parameterData));
        // State Clean up
        setParameter('');
        setSubParameter('');
        setDescription('');
        setFatal(false);
        setFatalChance(false);
        setNoOfTimes('');
        setDurationType('');
        setWeightage('');
        setInputValues([]);
        setHasRemark(false);
        setSubParamCritical("");
        setCriticalCheckbox("noncritical");
        setResponseType("Yes, No, NA, Partial");
    };

    const getTotalWeight = parameterData => {
        let weight = 0;
        if (Object.keys(parameterData).length > 0) {
            for (const key in parameterData) {
                for (const subParameter of parameterData[key]) {
                    weight += Number(subParameter.Weightage + '');
                }
            }
        }
        return weight;
    };

    useEffect(() => {
        setTotalWeightage(getTotalWeight(parameterData));
    }, [parameterData]);

    //  =====================================
    // CREATE AUDIT SHEET (Button Click)
    //  =====================================
    const submitRef = useRef(null);
    const [loader, setLoader] = useState(submitRef.current);
    const handleMasterSubmittion = async event => {
        if (submitRef.current) {
            return;
        }
        submitRef.current = true;
        setLoader(true);
        try {
            const parameterDataPayload = JSON.parse(
                JSON.stringify(parameterData)
            ); // Clone
            const total = getTotalWeight(parameterDataPayload);
            if (total < 50) {
                ErrorNotify('Minimum possible score must be 50.');
                return;
            } else if (isSaveDisabled) {
                ErrorNotify('Please fill all mandatory fields.');
            }
            const body = {
                data: {
                    Form_Name: selectedDepartment.formName,
                    Form_Type: 'Voice',
                    Is_Active: true,
                    Department: selectedDepartment.value,
                    Organisation: '{{org-id}}',
                    Audit_Response_Time: tat,
                    Custom_Fields_Schema: schema,
                    Custom_Fields_UI: uiSchema,
                },
            };

            const { data } = await client.post('/audit-forms', body);
            const currentDeptID = data?.data?.id;

            if (currentDeptID && currentDeptID !== '') {
                const parameterDataWithAuditFormId = addAuditFormID(
                    parameterDataPayload,
                    currentDeptID
                );
                const response = await client.post('/audit-subparameters', {
                    auditSheetData: parameterDataWithAuditFormId,
                });
                if (response.success) {
                    successNotify('The Audit Sheet has been Created');
                    setTimeout(() => {
                        navigate(auditSheetsHome);
                    }, 3000);
                } else {
                    ErrorNotify(
                        'Something went wrong. Please try again later!'
                    );
                    submitRef.current = false;
                    setLoader(false);
                }
            }
        } catch (error) {
            ErrorNotify('Something went wrong. Please try again later!');
            submitRef.current = false;
            setLoader(false);
        }
    };

    function addAuditFormID(parameterData, id) {
        const parameterDataCopy = { ...parameterData };
        for (const key in parameterDataCopy) {
            parameterDataCopy[key].forEach(item => {
                item.Audit_Form = id;
                const criticalItem = item.Critical_Type;
                item.Critical_Type = criticalItem ? criticalItem : 'None';
            });
        }
        return parameterDataCopy;
    }


    const addOption = () => {
        setInputValues([...inputValues, '']);
    };

    const handleInputChange = (index, value) => {
        const newInputValues = [...inputValues];
        newInputValues[index] = value;
        setInputValues(newInputValues);
    };

    const removeOption = index => {
        const newInputValues = [...inputValues];
        newInputValues.splice(index, 1);
        setInputValues(newInputValues);
    };

    const isAddSubParamDisabled = useMemo(() => {
        const inputValuesWithEmptyString = inputValues.some(el => el === '');
        const isDuplicate = new Set(inputValues).size !== inputValues.length;
        if (inputValuesWithEmptyString || isDuplicate) {
            return false;
        }
        if (fatalChance && (!noOfTimes || !durationType)) {
            return false;
        }
        if ((criticalCheckbox === 'critical' && subParamCritical === '') ||
            (criticalCheckbox === 'noncritical' && subParamCritical !== '')) {
            return false;
        }
        return (
            parameter?.value?.length >= 1 &&
            subParameter?.value?.length >= 1 &&
            weightage >= 0 &&
            weightage !== ''
        );
    }, [
        parameter,
        subParameter,
        weightage,
        inputValues,
        fatalChance,
        noOfTimes,
        durationType,
        criticalCheckbox,
        subParamCritical,
    ]);

    useEffect(() => {
        const subParameterOptions = _.get(parameterData, parameter?.value, []);
        const subParameterOptionsPredefined = _.get(subParaMeterOptionsDefault, parameter?.value, []);
        const uniqueOptionsOfSubParameter = filterPredefinedOptions(subParameterOptions, subParameterOptionsPredefined)
        SetSubParaMeterOptions(uniqueOptionsOfSubParameter)
    }, [parameter, parameterData]);

    const isSaveDisabled = useMemo(() => {
        return !selectedDepartment?.value || !tat;
    }, [selectedDepartment, tat]);

    const accordionRef = useRef(null);

    useEffect(() => {
        const handleOutsideClick = e => {
            if (
                accordionRef.current &&
                !accordionRef.current.contains(e.target)
            ) {
                setActiveKey(null);
            }
        };
        document.addEventListener('click', handleOutsideClick);
        return () => {
            document.removeEventListener('click', handleOutsideClick);
        };
    }, []);

    const [activeKey, setActiveKey] = useState(null);

    const handleBreadcrumbClick = link => {
        navigate(urlPath(link));
    };

    return (
        <>
            <div className='page-wrapper'>
                <div className='content pb-0'>
                    <div className='row'>
                        <div className='col-lg-12 content_wrap_main- content_wrap_style audit_create_padding_btm'>
                            <div className='content_wrap_comman'>
                                <div className='row'>
                                    <div className='col-md-12'>
                                        <div className='breadcrumb_box'>
                                            <Breadcrumb>
                                                <Breadcrumb.Item
                                                    as='li'
                                                    onClick={() =>
                                                        handleBreadcrumbClick(
                                                            '/'
                                                        )
                                                    }>
                                                    <img
                                                        src={
                                                            process.env
                                                                .PUBLIC_URL +
                                                            '/images/home_icon_pagig.svg'
                                                        }
                                                        alt=''
                                                    />
                                                </Breadcrumb.Item>
                                                <Breadcrumb.Item
                                                    as='li'
                                                    onClick={() =>
                                                        handleBreadcrumbClick(
                                                            auditSheetsHome
                                                        )
                                                    }>
                                                    Audit Sheets
                                                </Breadcrumb.Item>
                                                <Breadcrumb.Item
                                                    as='li'
                                                    active
                                                    aria-current='page'>
                                                    {mode} Audit Sheet
                                                </Breadcrumb.Item>
                                            </Breadcrumb>
                                        </div>
                                    </div>
                                </div>
                                <div className='row'>
                                    <div className='col-md-3'>
                                        <div>
                                            <div className='password_tab_body'>
                                                <div className='heading_box'>
                                                    <h3 className='page_title'>
                                                        {mode} Audit Sheet
                                                    </h3>
                                                    <p className='page_sub_title'>
                                                        <i>
                                                            <sup className='sup_manatory text-danger'>
                                                                *
                                                            </sup>
                                                            All fields are
                                                            mandatory
                                                        </i>
                                                    </p>
                                                </div>
                                                <Form
                                                    className='form_style_comman mt-4'
                                                    id='mainForm'
                                                    validated={obsValidation}
                                                    noValidate>
                                                    <div className='form-group '>
                                                        <Form.Label htmlFor='validationDefault04'>
                                                            OBS
                                                            <sup className='sup_manatory text-danger'>
                                                                *
                                                            </sup>
                                                        </Form.Label>
                                                        <ObsStructure
                                                            selectedDepartment={
                                                                selectedDepartment
                                                            }
                                                            setSelectedDepartment={
                                                                setSelectedDepartment
                                                            }
                                                            selectedDeptParent={
                                                                selectedDeptParent
                                                            }
                                                            setSelectedDeptParent={
                                                                setSelectedDeptParent
                                                            }
                                                            disabled={
                                                                mode == 'Edit'
                                                            }
                                                            selectPlaceholder={
                                                                true
                                                            }
                                                        />
                                                    </div>
                                                    <div className='form-group'>
                                                        <Form.Label>
                                                            Audit Response TAT{' '}
                                                            <sup className='sup_manatory text-danger'>
                                                                *
                                                            </sup>
                                                            <small className='ms-2'>
                                                                (in Days)
                                                            </small>
                                                        </Form.Label>
                                                        <Form.Control
                                                            required
                                                            type='number'
                                                            className='form-control'
                                                            placeholder='00'
                                                            value={tat}
                                                            min={1}
                                                            max={30}
                                                            onKeyDown={
                                                                handleKeyPress
                                                            }
                                                            onChange={e => {
                                                                setIsEdited(
                                                                    true
                                                                );
                                                                const inputValue =
                                                                    e.target
                                                                        .value;
                                                                if (
                                                                    inputValue <=
                                                                    30
                                                                ) {
                                                                    setTat(
                                                                        inputValue
                                                                    );
                                                                }
                                                            }}
                                                        />

                                                        <Form.Control.Feedback
                                                            type='invalid'
                                                            id='tatError'>
                                                            TAT is required and
                                                            should be between 1
                                                            and 30
                                                        </Form.Control.Feedback>
                                                    </div>
                                                </Form>
                                                <RendererFormControl
                                                    renderers={renderers}
                                                    setRenderers={setRenderers}
                                                    schema={schema}
                                                    setSchema={setSchema}
                                                    setIsEdited={setIsEdited}
                                                    uiSchema={uiSchema}
                                                    setUischema={setUischema}
                                                />
                                            </div>
                                        </div>
                                    </div>

                                    <div className='col-md-4'>
                                        <div className='prm_body'>
                                            <div className='heading_box'>
                                                <h3 className='page_title_sub_head'>
                                                    Create Parameter and Sub
                                                    Parameter
                                                </h3>
                                                <p className='page_sub_title'>
                                                    <i>
                                                        <sup className='sup_manatory text-danger'>
                                                            *
                                                        </sup>
                                                        All fields are mandatory
                                                    </i>
                                                </p>
                                            </div>

                                            <div className='form_width_comman mt-4'>
                                                <Form
                                                    validated={validated}
                                                    className='form_style_comman'
                                                    noValidate>
                                                    <Row>
                                                        <Col
                                                            md='12'
                                                            className='mb-3'>
                                                            <Form.Label
                                                                md='6'
                                                                htmlFor='validationDefault01'
                                                                className='two_column_title'>
                                                                <span>
                                                                    Parameter
                                                                    <sup className='sup_manatory text-danger'>
                                                                        *
                                                                    </sup>
                                                                </span>{' '}
                                                                <p>
                                                                    <i>
                                                                        Character
                                                                        Limit
                                                                        250
                                                                    </i>
                                                                </p>
                                                            </Form.Label>
                                                            <Form.Control
                                                                as={CustomParameter}
                                                                placeholder='Enter Parameter'
                                                                id='searchParam'
                                                                value={parameter.value}
                                                                className='prm-control'
                                                                maxLength={250}
                                                                isNotValid={
                                                                    parameter
                                                                        ? false
                                                                        : true
                                                                }
                                                                onChange={e => {
                                                                    e = { ...e, label: e.label.trim(), value: e.value.replace(/\n/g, ' ').trim() }
                                                                    if (e?.value?.length > 250) {
                                                                        return;
                                                                    }
                                                                    const tempOptions =
                                                                        options.filter(
                                                                            option =>
                                                                                option.value !==
                                                                                e.value
                                                                        );
                                                                    tempOptions.push(
                                                                        e
                                                                    );
                                                                    setOptions(
                                                                        tempOptions
                                                                    );
                                                                    setParameter(e);
                                                                    setSubParameter("");
                                                                    const criticalSet = _.get(critical, e.value, 'noncritical');
                                                                    setCriticalCheckbox(criticalSet);
                                                                    setSubParamCritical("");
                                                                }}
                                                                required
                                                            />
                                                            <Form.Control.Feedback
                                                                type='invalid'
                                                                id='searchError'>
                                                                Parameter is
                                                                required
                                                            </Form.Control.Feedback>
                                                        </Col>
                                                        <div className='col-lg-12 mb-3'>
                                                            <label>Select critical
                                                                <sup className='sup_manatory text-danger'>
                                                                    *
                                                                </sup></label>
                                                            <div className='row'>
                                                                <div className='col-lg-6'>
                                                                    <Form.Check className="form-check-inline">
                                                                        <FormCheck.Input
                                                                            type="radio"
                                                                            name="bsradio"
                                                                            id="critical"
                                                                            checked={criticalCheckbox === 'critical'}
                                                                            onChange={(e) => setCriticalCheckbox(e.target.id)}
                                                                            disabled={_.get(critical, parameter?.value)}
                                                                        />
                                                                        <FormCheck.Label htmlFor="critical" className="pl-2 mb-0">Critical</FormCheck.Label>
                                                                    </Form.Check>
                                                                </div>
                                                                <div className='col-lg-6'>
                                                                    <Form.Check className="form-check-inline">
                                                                        <FormCheck.Input
                                                                            type="radio"
                                                                            name="bsradio"
                                                                            id="noncritical"
                                                                            checked={criticalCheckbox === 'noncritical'}
                                                                            onChange={(e) => {
                                                                                setCriticalCheckbox(e.target.id);
                                                                                setSubParamCritical("");
                                                                            }}
                                                                            disabled={_.get(critical, parameter?.value)}
                                                                        />
                                                                        <FormCheck.Label htmlFor="noncritical" className="pl-2 mb-0">Non-Critical</FormCheck.Label>
                                                                    </Form.Check>
                                                                </div>
                                                                <div className='col-lg-12'>
                                                                    <div className="caption_txt mt-1">(Note: Selection of critical will be applied to all sub parameters) </div>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <Col
                                                            md='12'
                                                            className='mb-3'>
                                                            <Form.Label
                                                                md='6'
                                                                htmlFor='validationDefault02'
                                                                className='two_column_title'>
                                                                <span>
                                                                    Sub Parameter
                                                                    <sup className='sup_manatory text-danger'>
                                                                        *
                                                                    </sup>
                                                                </span>{' '}
                                                                <p><i>Character Limit 250</i></p>
                                                            </Form.Label>
                                                            <Form.Control
                                                                as={CustomSubParameter}
                                                                placeholder='Enter Sub Parameter'
                                                                id='searchParam'
                                                                value={subParameter.value}
                                                                className='prm-control'
                                                                maxLength={250}
                                                                isNotValid={
                                                                    subParameter
                                                                        ? false
                                                                        : true
                                                                }
                                                                onChange={e => {
                                                                    e = { ...e, label: e.label.trim(), value: e.value.replace(/\n/g, ' ').trim() }
                                                                    if (e?.value?.length > 250) {
                                                                        return;
                                                                    }
                                                                    setSubParameter(e);
                                                                }}
                                                                required
                                                            />
                                                            <Form.Control.Feedback
                                                                type='invalid'
                                                                id='searchError'>
                                                                Sub Parameter is
                                                                required
                                                            </Form.Control.Feedback>
                                                        </Col>

                                                        {loggedInUser.orgRole && loggedInUser.orgRole.startsWith('Sys.') && <Col
                                                            md='12'
                                                            className='mb-3'>
                                                            <Form.Label
                                                                md='6'
                                                                htmlFor='validationDefault023'
                                                                className='two_column_title'>
                                                                <span>Description</span>{' '}
                                                                <p><i>Character Limit 250</i></p>
                                                            </Form.Label>

                                                            <Form.Control
                                                                className='hg-prm-control'
                                                                as='textarea'
                                                                rows={1}
                                                                placeholder='Enter Description'
                                                                id='validationDefault023'
                                                                value={description}
                                                                onChange={e => { validateNameWithRegex(e, e => setDescription(e.target.value), { characterLimit: 250 }) }}
                                                            />
                                                        </Col>}
                                                    </Row>

                                                    <div className='row'>
                                                        <div className='col-lg-12'>
                                                            <label>Response Type
                                                            </label>
                                                            <Form.Select
                                                                value={responseType}
                                                                onChange={(e) => setResponseType(e.target.value)}
                                                                className=" form-select mb-3"
                                                                aria-label=".form-select-lg example">
                                                                {
                                                                    responseTypeArray.map((option) =>
                                                                    (
                                                                        <option key={option} value={option}>
                                                                            {option}
                                                                        </option>
                                                                    ))
                                                                }
                                                            </Form.Select>
                                                        </div>
                                                    </div>

                                                    <div className='row'>
                                                        <div className='col-lg-12'>
                                                            <label>Critical Sub Parameter
                                                                {criticalCheckbox == "critical" && <sup className='sup_manatory text-danger'>
                                                                    *
                                                                </sup>}
                                                            </label>
                                                            <Form.Select
                                                                disabled={criticalCheckbox == "noncritical"}
                                                                value={subParamCritical}
                                                                onChange={(e) => setSubParamCritical(e.target.value)}
                                                                className=" form-select mb-3"
                                                                aria-label=".form-select-lg example">
                                                                <option value="">Select</option>
                                                                {
                                                                    criticalArray.map((option) =>
                                                                    (
                                                                        <option key={option} value={option}>
                                                                            {option}
                                                                        </option>
                                                                    ))
                                                                }
                                                            </Form.Select>
                                                        </div>
                                                    </div>

                                                    <Row>
                                                        <Col
                                                            lg='5'
                                                            className='form-group'>
                                                            <Form.Check className='form-switch d-flex align-items-center no_rt_padd ms-0 ps-0'>
                                                                <Form.Check.Input
                                                                    type='checkbox'
                                                                    className='me-2 ms-0'
                                                                    id='flexSwitchCheckDefault'
                                                                    checked={
                                                                        fatal
                                                                    }
                                                                    onChange={e => {
                                                                        handleFatal(
                                                                            e
                                                                        );
                                                                    }}
                                                                />
                                                                <Form.Check.Label htmlFor='flexSwitchCheckDefault'>
                                                                    Fatal
                                                                </Form.Check.Label>
                                                            </Form.Check>
                                                        </Col>
                                                        <Col
                                                            lg='7'
                                                            className='form-group'>
                                                            {
                                                                <Form.Check className='form-switch d-flex align-items-center justify-content-end no_rt_padd ms-0 ps-0'>
                                                                    <Form.Check.Input
                                                                        className='me-2 ms-0'
                                                                        type='checkbox'
                                                                        id='flexSwitchCheckDefault2'
                                                                        checked={
                                                                            fatalChance
                                                                        }
                                                                        onChange={
                                                                            handleCheckboxChange
                                                                        }
                                                                    />
                                                                    <Form.Check.Label htmlFor='flexSwitchCheckDefault2'>
                                                                        Fatal if
                                                                        Repeated?
                                                                    </Form.Check.Label>
                                                                </Form.Check>
                                                            }
                                                        </Col>
                                                    </Row>

                                                    {fatalChance && (
                                                        <Row>
                                                            <Col
                                                                lg='5'
                                                                className='form-group'>
                                                                <Form.Label
                                                                    md='6'
                                                                    htmlFor='validationDefaultTime'>
                                                                    Number Of
                                                                    Times
                                                                </Form.Label>
                                                                <Form.Control
                                                                    type='number'
                                                                    placeholder='00'
                                                                    id='validationDefaultTime'
                                                                    value={
                                                                        noOfTimes
                                                                    }
                                                                    onKeyDown={
                                                                        handleKeyPress
                                                                    }
                                                                    onChange={e => {
                                                                        const inputValue =
                                                                            e
                                                                                .target
                                                                                .value;
                                                                        if (
                                                                            inputValue <=
                                                                            100
                                                                        ) {
                                                                            setNoOfTimes(
                                                                                inputValue
                                                                            );
                                                                        }
                                                                    }}
                                                                    required
                                                                    min={1}
                                                                    max={100}
                                                                />

                                                                <Form.Control.Feedback type='invalid'>
                                                                    Number of
                                                                    Times must
                                                                    be &gt;=1
                                                                    and &lt;=
                                                                    100
                                                                </Form.Control.Feedback>
                                                            </Col>
                                                            <Col
                                                                lg='2'
                                                                className='false_div'></Col>
                                                            <Col
                                                                lg='5'
                                                                className='form-group'>
                                                                <Form.Label htmlFor='validationDefaultDuration'>
                                                                    Duration
                                                                </Form.Label>
                                                                <Form.Select
                                                                    id='validationDefaultDuration'
                                                                    value={
                                                                        durationType
                                                                    }
                                                                    onChange={
                                                                        handleDurationTypeChange
                                                                    }
                                                                    required>
                                                                    <option value=''>
                                                                        Select
                                                                    </option>
                                                                    <option value='weekly'>
                                                                        Weekly
                                                                    </option>
                                                                    <option value='monthly'>
                                                                        Monthly
                                                                    </option>
                                                                </Form.Select>
                                                                <Form.Control.Feedback type='invalid'>
                                                                    Duration is
                                                                    required
                                                                </Form.Control.Feedback>
                                                            </Col>
                                                        </Row>
                                                    )}
                                                    <Row>
                                                        <Col
                                                            xl='5'
                                                            lg='6'
                                                            md='4'
                                                            sm='7'
                                                            className='form-group'>
                                                            <Form.Label htmlFor='validationDefaultWeightage'>
                                                                Weightage
                                                            </Form.Label>
                                                            <Form.Control
                                                                type='number'
                                                                id='validationDefaultWeightage'
                                                                value={
                                                                    weightage
                                                                }
                                                                min={0}
                                                                step='1'
                                                                onKeyDown={
                                                                    handleKeyPress
                                                                }
                                                                onChange={e => {
                                                                    const inputValue =
                                                                        e.target
                                                                            .value;
                                                                    setWeightage(
                                                                        inputValue
                                                                    );
                                                                }}
                                                                required
                                                                placeholder='00'
                                                            />
                                                            <Form.Control.Feedback type='invalid'>
                                                                Weightage is
                                                                required, (must
                                                                be &gt; 1)
                                                            </Form.Control.Feedback>
                                                        </Col>
                                                        <Col
                                                            xl='1'
                                                            lg='1'
                                                            md='1'
                                                            className='false_div'></Col>
                                                        <Col
                                                            xl='6'
                                                            lg='6'
                                                            md='5'
                                                            sm='5'
                                                            className='form-group text-end'>
                                                            <div className='d-inline-block text-start'>
                                                                <Form.Label htmlFor='validationDefaultWeightage'>
                                                                    Possible
                                                                    score
                                                                </Form.Label>
                                                                <div className='d-flex total_weightage'>
                                                                    <div className='total_weightage_bx dashed_border'>
                                                                        <strong>
                                                                            {
                                                                                totalWeightage
                                                                            }
                                                                        </strong>
                                                                    </div>
                                                                </div>
                                                                <div className='caption_txt mt-1'>
                                                                    Minimum
                                                                    possible
                                                                    score must
                                                                    be 50
                                                                </div>
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <div className='d-inline-block text-start checkbox_custom checkbox_small'>
                                                            <Form.Label htmlFor='validationDefaultWeightage'>
                                                                <label className='form-label'>
                                                                    <input
                                                                        type='checkbox'
                                                                        className='me-2 form-check-input'
                                                                        checked={
                                                                            hasRemark
                                                                        }
                                                                        onChange={e =>
                                                                            setHasRemark(
                                                                                e
                                                                                    .target
                                                                                    .checked
                                                                            )
                                                                        }
                                                                        id='flexCheckChecked'
                                                                    />
                                                                    Add remarks
                                                                    section
                                                                </label>
                                                            </Form.Label>

                                                            <div className='form-group audit_sheet_Accordian'>
                                                                <Accordion
                                                                    ref={
                                                                        accordionRef
                                                                    }
                                                                    activeKey={
                                                                        activeKey
                                                                    }
                                                                    onSelect={e =>
                                                                        setActiveKey(
                                                                            e
                                                                        )
                                                                    }>
                                                                    <Accordion.Item eventKey='0'>
                                                                        <Accordion.Header>
                                                                            Add
                                                                            Reasons
                                                                            Section
                                                                        </Accordion.Header>
                                                                        <Accordion.Body>
                                                                            <div className='mb-2'>
                                                                                <button
                                                                                    type='button'
                                                                                    className='btn_link btn_transparent'
                                                                                    onClick={e =>
                                                                                        addOption()
                                                                                    }>
                                                                                    +
                                                                                    Add
                                                                                    Reason
                                                                                </button>
                                                                            </div>
                                                                            <div className='add_reason_scroll scrollbar_style'>
                                                                                {inputValues.map(
                                                                                    (
                                                                                        value,
                                                                                        index
                                                                                    ) => {
                                                                                        const isDuplicate =
                                                                                            value &&
                                                                                            inputValues.indexOf(
                                                                                                value
                                                                                            ) !==
                                                                                            index;
                                                                                        return (
                                                                                            <div
                                                                                                className='form-group'
                                                                                                key={`Audit sheet Option ${index + 1}`}>
                                                                                                <label>{`Reason ${index + 1}`}</label>
                                                                                                <div className='d-flex justify-content-between'>
                                                                                                    <input
                                                                                                        data-option-index={
                                                                                                            index
                                                                                                        }
                                                                                                        ref={
                                                                                                            index ===
                                                                                                                inputValues.length -
                                                                                                                1
                                                                                                                ? newOptionInputRef
                                                                                                                : null
                                                                                                        }
                                                                                                        type='text'
                                                                                                        className='form-control'
                                                                                                        placeholder={`Option ${index + 1}`}
                                                                                                        value={
                                                                                                            inputValues[
                                                                                                            index
                                                                                                            ]
                                                                                                        }
                                                                                                        onKeyDown={e => {
                                                                                                            if (
                                                                                                                e.key ===
                                                                                                                'Enter' &&
                                                                                                                e.target.value?.trim()
                                                                                                                    .length >
                                                                                                                1 &&
                                                                                                                inputValues[
                                                                                                                index +
                                                                                                                1
                                                                                                                ] ===
                                                                                                                undefined
                                                                                                            ) {
                                                                                                                addOption();
                                                                                                                e.preventDefault();
                                                                                                            } else if (
                                                                                                                e.key ===
                                                                                                                'Enter' &&
                                                                                                                e.target.value?.trim()
                                                                                                                    .length ==
                                                                                                                0 &&
                                                                                                                inputValues[
                                                                                                                index +
                                                                                                                1
                                                                                                                ] ===
                                                                                                                undefined
                                                                                                            ) {
                                                                                                                e.preventDefault();
                                                                                                            }
                                                                                                            return true;
                                                                                                        }}
                                                                                                        onChange={e =>
                                                                                                            validateNameWithRegex(
                                                                                                                e,
                                                                                                                callback =>
                                                                                                                    handleInputChange(
                                                                                                                        index,
                                                                                                                        callback
                                                                                                                            .target
                                                                                                                            .value
                                                                                                                    ),
                                                                                                                25
                                                                                                            )
                                                                                                        }
                                                                                                    />
                                                                                                    <button
                                                                                                        className='ms-3 btn_transparent'
                                                                                                        type='button'
                                                                                                        onClick={e => {
                                                                                                            removeOption(
                                                                                                                index
                                                                                                            );
                                                                                                            e.preventDefault();
                                                                                                        }}>
                                                                                                        <img
                                                                                                            src={imagePath(
                                                                                                                '/images/delete_dark.svg'
                                                                                                            )}
                                                                                                        />
                                                                                                    </button>
                                                                                                </div>
                                                                                                {isDuplicate && (
                                                                                                    <p className='text-danger'>{`Duplicate field`}</p>
                                                                                                )}
                                                                                            </div>
                                                                                        );
                                                                                    }
                                                                                )}
                                                                            </div>
                                                                        </Accordion.Body>
                                                                    </Accordion.Item>
                                                                </Accordion>
                                                            </div>
                                                        </div>
                                                    </Row>

                                                    <Row className='mt-2'>
                                                        <Col
                                                            lg='12'
                                                            className='form-group btn_right'>
                                                            <Button
                                                                variant='btn comman_btn'
                                                                type='button'
                                                                onClick={
                                                                    hanldeAddSubParameter
                                                                }
                                                                disabled={
                                                                    !isAddSubParamDisabled
                                                                }>
                                                                Add
                                                            </Button>
                                                        </Col>
                                                    </Row>
                                                </Form>
                                            </div>
                                        </div>
                                    </div>

                                    <div className='col-md-5'>
                                        <div className='heading_box'>
                                            <h3 className='page_title_sub_head mb-2'>
                                                Audit Sheet Parameter Preview
                                            </h3>
                                        </div>
                                        <ParameterList
                                            setParameterData={parameterData => {
                                                setParameterData(parameterData);
                                                setTotalWeightage(
                                                    getTotalWeight(
                                                        parameterData
                                                    )
                                                );
                                            }}
                                            parameterData={parameterData}
                                            setIsEdited={setIsEdited}
                                            totalWeightage={totalWeightage}
                                            setTotalWeightage={
                                                setTotalWeightage
                                            }
                                            setParameter={setParameter}
                                            mode={mode}
                                            critical={critical}
                                            setCritical={setCritical}
                                            options={options}
                                            setOptions={setOptions}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='row masterSaveBtnDiv'>
                <div className='col-md-12 materSaveBtn'>
                    {
                        <Button
                            disabled={
                                mode === 'Edit'
                                    ? !isEdited ||
                                    isSaveDisabled ||
                                    totalWeightage < 50
                                    : isSaveDisabled || totalWeightage < 50
                            }
                            form={'mainForm'}
                            onClick={handleMasterSubmittion}
                            className='audit-save-disabled btn comman_btn'>
                            {loader ? (
                                <>
                                    <Spinner className='spinner-button'></Spinner>
                                    <span> Save</span>
                                </>
                            ) : (
                                'Save'
                            )}
                        </Button>
                    }
                </div>
            </div>
        </>
    );
};

export default AuditSheet;


function filterPredefinedOptions(subParameterOptions, subParameterOptionsPredefined) {
    const subParameterNames = new Set(subParameterOptions.map(option => option.Subparameter_Name));
    return subParameterOptionsPredefined.filter(option => !subParameterNames.has(option.label));
}
